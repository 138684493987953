@font-face {
  font-family: "GazerSans";
  src:
    url("./assets/fonts/GazerSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/GazerSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GazerSans";
  src:
    url("./assets/fonts/GazerSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/GazerSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GazerSans";
  src:
    url("./assets/fonts/GazerSans-Light.woff2") format("woff2"),
    url("./assets/fonts/GazerSans-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "GazerSans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #202020;
  border-radius: 10px;
  border: 1px solid transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

* {
  scrollbar-color: #202020 transparent;
  scrollbar-width: thin;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #fff !important;
}

.MuiTableContainer-root {
  scrollbar-color: #fff transparent;
  scrollbar-width: thin;
}
